import { leadProductSearchService } from '@/services/leadProductSearch.service';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useProductSearch = (key: string | undefined) => {
	const queryClient = useQueryClient();

	const mutationOptions = {
		onError: () => {
			console.log(`There was an error in ${key}`);
		},
		onSettled: () => {
			queryClient.invalidateQueries();
		},
	};
	switch (key) {
		case 'advancedSearch':
			return useMutation(leadProductSearchService.advancedSearch, mutationOptions);
		case 'hotKeywordSearch':
			return useMutation(leadProductSearchService.hotKeywordSearch, mutationOptions);

		default:
			throw new Error(`Unsupported key: ${key} in useAdminManagement hook`);
	}
};
