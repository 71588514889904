import { http } from '@/core/services/http.service';
import { baseUrl } from './index';

class LeadProductSearchService {
	advancedSearch = async ({ requestBody, countryCode }: any) => {
		try {
			const response: any = await http.get(`${baseUrl}/product/search`, {
				//const response: any = await http.get('http://127.0.0.1:5001/search', {
				params: requestBody,
				headers: {
					NotshowCustomLoading: true,
					countryCode,
				},
			});
			return response;
		} catch (err) {
			console.log('Error in advancedSearch', err);
		}
	};
	hotKeywordSearch = async ({ requestBody, countryCode }: any) => {
		try {
			const response: any = await http.get(`${baseUrl}/product/search-hot-keywords`, {
			//const response: any = await http.get('http://127.0.0.1:5001/search-hot-keywords', {
				params: requestBody,
				headers: {
					NotshowCustomLoading: true,
					countryCode,
				},
			});
			return response;
		} catch (err) {
			console.log('Error in hotKeywordSearch', err);
		}
	};
}

export const leadProductSearchService = new LeadProductSearchService();
