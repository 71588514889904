import { Form } from 'antd-mobile';
import { PopupList } from '../index';
// import { Rule } from 'antd/es/form';
import { useState } from 'react';
import { setScrollTop } from '@/core/utils';

interface IProps {
	isProductFamily?: boolean;
	customComponent?: any;
	label: any;
	name: string;
	rules: any[];
	popupListTitle: string;
	options: any;
	popupListOnCustomChange?: (value: string, item?: any) => void;
	search?: boolean;
	onChange?: (value: Array<string>) => void;
	disabled?: boolean;
	dependencies?: string[];
	setSearchResultProductFamily?: (value: any) => void;
	setProdFamilyListSearchKeyword?: (value: string) => void;
	setAdvancedSearchKeyword?: (value: string) => void;
	setIsRecommendedProdFamilyShown?: (value: boolean) => void;
	setIsRecommendedProdFamilySelected?: (value: boolean) => void;
}

const FormPopupList = ({
	isProductFamily = false,
	customComponent = null,
	label,
	name,
	rules,
	popupListTitle,
	options,
	popupListOnCustomChange,
	search = false,
	onChange,
	disabled,
	dependencies,
	setSearchResultProductFamily,
	setProdFamilyListSearchKeyword,
	setAdvancedSearchKeyword,
	setIsRecommendedProdFamilyShown,
	setIsRecommendedProdFamilySelected,
}: IProps) => {
	const [formItemTop, setFormItemTop] = useState(0);
	const onClickPoupList = (e: any, ref: any) => {
		const headerDom = document.getElementById('leads_header');
		if (headerDom) {
			const headerRect = headerDom.getBoundingClientRect();
			const formItemDomRect = e.target.getBoundingClientRect();
			const distance = formItemDomRect.top - headerRect.bottom;
			setFormItemTop(distance);
			ref.current?.openVisible();
		}
	};

	const onCancel = () => {
		formItemTop && setScrollTop(formItemTop);
	};

	return (
		<Form.Item
			disabled={disabled}
			label={label}
			name={name}
			arrow
			onClick={onClickPoupList}
			rules={rules}
			dependencies={dependencies}
		>
			<PopupList
				name={name}
				setProdFamilyListSearchKeyword={setProdFamilyListSearchKeyword}
				customComponent={customComponent}
				isProductFamily={isProductFamily}
				search={search}
				onCancel={onCancel}
				onCustomChange={popupListOnCustomChange && popupListOnCustomChange}
				options={options}
				title={popupListTitle}
				onChange={onChange}
				setSearchResultProductFamily={setSearchResultProductFamily}
				setAdvancedSearchKeyword={setAdvancedSearchKeyword}
				setIsRecommendedProdFamilyShown={setIsRecommendedProdFamilyShown}
				setIsRecommendedProdFamilySelected={setIsRecommendedProdFamilySelected}
			/>
		</Form.Item>
	);
};
export default FormPopupList;
