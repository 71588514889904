import {
	LazyLeadAdminPanel,
	LazyLeadStatusReport,
	LazyContentHubViewPage,
	LazyContentHubDetailsPage,
	LazyContentHubPage,
	LazyFormPage,
	LazyHome,
	LazySurveyHubPage,
	LazyTranslationalRequest,
	LazyMasteryChallenge,
	LazyMasteryChallengeCampaignDetails,
} from './pages';

export const ApplayoutRoutes = [
	{
		path: '/',
		element: <LazyHome />,
	},
	{
		path: '/contenthub',
		element: <LazyContentHubPage />,
		children: [
			{
				path: 'details/:productDivision',
				element: <LazyContentHubDetailsPage />,
				children: [
					{
						path: 'display/:fileName',
						element: <LazyContentHubViewPage />,
					},
				],
			},
		],
	},
	{
		path: '/survey',
		element: <LazySurveyHubPage />,
	},
	{
		path: '/form',
		element: <LazyFormPage />,
	},
	{
		path: '/home',
		element: <LazyHome />,
	},
	{
		path: '/leadreport',
		element: <LazyLeadStatusReport />,
	},
	{
		path: '/admin',
		element: <LazyLeadAdminPanel />,
	},
	{
		path: '/translationrequest',
		element: <LazyTranslationalRequest />,
	},
	{
		path: '/masterychallenge',
		element: <LazyMasteryChallenge />,
		children: [
			{
				path: 'terms',
				element: <LazyMasteryChallengeCampaignDetails />,
			},
		],
	},
];
